<template>
<div>
    <!--<div class="fixed-plugin" v-click-outside="closeDropDown">
    <div class="dropdown show-dropdown" :class="{show: isOpen}" >
      <a class="settings-icon" aria-expanded="false">
        <font-awesome-icon icon="wrench" size="1x" @click="toggleDropDown" />

      </a>
      <ul class="dropdown-menu" :class="{show:isOpen}">
        <li class="header-title">Sidebar Background</li>
        <li class="adjustments-line" v-if="!darkblack">
          <a class="switch-trigger background-color">x
            <div class="badge-colors text-center">
              <span v-for="item in sidebarColors" :key="item.color" class="badge filter"
                    :class="[`badge-${item.color}`,{active:item.active}]"
                    :data="item.color"
                    @click="changeSidebarBackground(item)">
              </span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
        <li data-v-157cd3ac="" class="adjustments-line text-center color-change">
          <span data-v-157cd3ac="" class="color-label">LIGHT MODE </span>
          <span data-v-157cd3ac="" class="badge light-badge mr-2" @click="ligth1($event)"></span>
          <span data-v-157cd3ac="" class="badge dark-badge ml-2" @click="dark($event)"></span>
          <span data-v-157cd3ac="" class="color-label"> DARK MODE</span>
        </li>
        <li class="adjustments-line" v-if="!darkblack">
          <span>Nabvar background</span>
          <a class="switch-trigger background">x
            <div class="badge-colors text-center">
              <span v-for="item in navbarColors" :key="item.color" class="badge filter"
                    :class="[`badge-${item.color}`,{active:item.active}]"
                    :data-color="item.color"
                    @click="changeNavbarBackground(item)">
              </span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
        <li class="button-container">
          <a href="https://www.creative-tim.com/product/vue-white-dashboard" target="_blank" class="btn btn-primary btn-block btn-round">Download Now</a>
          <a href="https://demos.creative-tim.com/vue-white-dashboard/documentation" target="_blank" class="btn btn-default btn-block btn-round">
            Documentation
          </a>
        </li>

        <li class="header-title">Thank you for sharing!</li>

        <li class="button-container text-center">
        </li>
      </ul>
    </div>
  </div>-->
  <div class="color-theme">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-6 mb-3" v-for="item in sidebarColors" :key="item.color">
        <a :data-color="item.color" class="dfs" :class="item.active+'-class'" title="Personalizar a color Azul" @click="changeTheme(item, $parent)">
          <!--<div class="img-theme" :style="{'background': `url(${require('../../assets/image'+item.img)})`}"></div>-->
          <img alt="Blue theme" :src="require('../../assets/image'+item.img)"/>
        </a>
      </div>
    </div>
  </div>

  </div>
</template>
<script>
import {mapState, mapMutations, mapActions} from "vuex";
export default {
    name: "confColors",
    props:{
      backgroundColor: String,
      backgroundNavbar: String,
    },
    data(){
      return {
        isOpen: false,
      }
    },
    computed: {
      ...mapState("dark", ["darkblack"]),
      ...mapState("colors", ["sidebarColors"]),
      ...mapState(["close"])
    },
    created() {
      console.log(this.sidebarColors);
    },
    methods:{
      ...mapMutations("dark",["ligth1", "dark", "modeDark"]),
      ...mapActions('colors', ['toggleList', 'darkColors']),
      // toggleDropDown(){
      //   this.isOpen = !this.isOpen
      // },
      // closeDropDown(){
      //   this.isOpen = false
      // },
      changeTheme(item, taht){
        let elemento = taht.$parent.$el;
        let cerrar = this.close;
        if(item.value == "black"){
          this.darkColors()
          this.dark({elemento, cerrar})
        }
        else{
          this.ligth1(elemento)
          this.toggleList({item, cerrar})
        }
        // this.toggleList(item)
      },
      // toggleList(list, itemToActivate) {
      //   list.forEach((listItem) => {
      //     listItem.active = false
      //   });
      //   itemToActivate.active = true
      // },
      // changeSidebarBackground(item){
      //   debugger;
      //   this.$emit('update:backgroundColor', item.value)
      //   this.toggleList(this.sidebarColors, item)
      // },
      // changeNavbarBackground(item){
      //   this.$emit('update:backgroundNavbar', item.value)
      //   this.toggleList(this.navbarColors, item)
      // },
      
    },
    mounted() {
      this.modeDark(this);
    },
}
</script>