<template>
    <div id="themes">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <card type="config-themes default">
                        <template slot="header">
                            <div class="d-flex justify-content-between align-center">
                                <h1>{{$t('temas.conf')}}</h1>
                                <div class="bar-collapse">
                                    <switch-form v-model="cerrar" :label="$t('temas.barra')" name="Barra" />
                                </div>
                            </div>
                        </template>
                    </card>
                </div>
                <div class="col-12">
                    <configColors :background-color.sync="backgroundColor" ></configColors>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Card from "@/components/card/Card.component";
import SwitchForm from "@/components/switch/SwitchForms.component";
import configColors from "@/components/configColors/ConfigColors.component.vue";
export default {
    name: "Themes",
    components: {
        Card,
        SwitchForm,
        configColors
    },
    data() {
        return {
            cerrar: "false",
            backgroundColor: "primary",
            backgroundNavbar: "primary"
        }
    },
    watch: {
        cerrar(newVal){
            this.$store.commit('CerrarExpandir')
        }
    },
}
</script>